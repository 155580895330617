import { castToBoolean } from '@/helpers/utils';

export const ENABLE_EXTERNAL_SCRIPTS = castToBoolean(process.env.NEXT_PUBLIC_ENABLE_EXTERNAL_SCRIPTS);
export const ENABLE_NEW_RELIC = castToBoolean(process.env.NEXT_PUBLIC_ENABLE_NEW_RELIC);
export const REDIRECT_SIMULATOR = String(process.env.NEXT_PUBLIC_REDIRECT_SIMULATOR);
export const NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED = castToBoolean(process.env.NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED);
export const ENABLE_CHANGE_PROFILE = castToBoolean(process.env.NEXT_PUBLIC_ENABLE_CHANGE_PROFILE);
export const URL_HELPDESK_MFE = String(process.env.NEXT_PUBLIC_HELPDESK_MFE);
export const URL_REPORTS_MFE = String(process.env.NEXT_PUBLIC_REPORTS_MFE);
export const NEXT_PUBLIC_SENTRY_DSN = String(process.env.NEXT_PUBLIC_SENTRY_DSN);
export const TRACES_SAMPLE_RATE_FOR_SENTRY_PRODUCTION = 0.2;
export const NEXT_PUBLIC_ENVIRONMENT = String(process.env.NEXT_PUBLIC_ENVIRONMENT);
export const NEXT_PUBLIC_VERSION = String(process.env.NEXT_PUBLIC_VERSION);
export const NEXT_PUBLIC_ENABLE_SENTRY = castToBoolean(process.env.NEXT_PUBLIC_ENABLE_SENTRY);
export const ENABLE_NEW_MENU = castToBoolean(process.env.NEXT_PUBLIC_ENABLE_NEW_MENU);
export const NEXT_PUBLIC_VALENT_DS_IS_ENABLED = castToBoolean(process.env.NEXT_PUBLIC_VALENT_DS_IS_ENABLED);
export const NEXT_PUBLIC_AMPLITUDE_API_KEY = String(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
